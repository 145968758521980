import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField
} from '@material-ui/core';

import Modal from '../../../shared/Modals/Modal';
import AutoSuggestInput from '../../../shared/AutoSuggestInput';
import CustomSelect from '../../../shared/Selects/CustomSelect';
import {
  pointOfSaleValidation,
  saleChannelValidation
} from './SupplierValidations';
import LoadingButton from '../../../shared/Buttons/LoadingButton';
import ConfirmDialogModal from '../../../shared/Modals/ConfirmDialog';
import {
  promissoryTypes,
  processesOrder
} from '../constants/SupplierPreferences';

class SupplierEditConfig extends React.Component {
  constructor(props) {
    super(props);
    const {
      orderOfProcesses,
      defaultSaleChannel,
      defaultPointOfSale,
      allowedPromissoryType,
      siteDelivery,
      isBigBox,
      canSelectSaleChannel,
      canSelectPointOfSale,
      canChangeSiteDelivery,
      canReprintSaleDocuments,
      canSellWithSecureSale,
      canSkipInvoiceValidation,
      requiresApprovalBeforeSaleCancel,
      requiresApprovalBeforeSaleReturn,
      reportsShowVoluntaryInsurance,
      requiresBin,
      canValidateMobileOnSale,
      canSellVoluntaryInsuranceLayerTwo,
      canSellWithMegaquota,
      canValidatePetSale,
      canSellToCommercial,
      docRevisionPercentage,
    } = this.props.supplierInfo;

    this.state = {
      order_of_processes: orderOfProcesses || '',
      default_sale_channel_id: defaultSaleChannel
        ? defaultSaleChannel.id
        : null,
      default_point_of_sale_id: defaultPointOfSale
        ? defaultPointOfSale.id
        : null,
      default_point_of_sale: defaultPointOfSale ? defaultPointOfSale.name : '',
      default_sale_channel: defaultSaleChannel ? defaultSaleChannel.name : '',
      allowed_promissory_type: allowedPromissoryType || '',
      site_delivery: siteDelivery,
      is_big_box: isBigBox,
      can_select_sale_channel: canSelectSaleChannel,
      can_select_point_of_sale: canSelectPointOfSale,
      can_change_site_delivery: canChangeSiteDelivery,
      can_reprint_sale_documents: canReprintSaleDocuments,
      can_skip_invoice_validation: canSkipInvoiceValidation,
      requires_approval_before_sale_cancel: requiresApprovalBeforeSaleCancel,
      requires_approval_before_sale_return: requiresApprovalBeforeSaleReturn,
      reports_show_voluntary_insurance: reportsShowVoluntaryInsurance,
      can_sell_with_secure_sale: canSellWithSecureSale,
      requires_bin: requiresBin || false,
      can_validate_mobile_on_sale: canValidateMobileOnSale,
      can_sell_voluntary_insurance_layer_two: canSellVoluntaryInsuranceLayerTwo,
      can_sell_with_megaquota: canSellWithMegaquota,
      can_validate_pet_sale: canValidatePetSale,
      can_sell_to_commercial: canSellToCommercial,
      doc_revision_percentage: docRevisionPercentage,
      isOpen: false
    };
  }

  handleCheckBox = name => event => {
    this.setState({ [name]: event.target.checked });
    if (name == 'requires_bin' && event.target.checked) {
      this._handleOpenModal();
    }
  };

  _handleChangeSelect = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  _handleAutoSuggest = name => (event, { newValue }) => {
    let id = null;
    let saleChannels = this.props.saleChannels;
    name === 'default_sale_channel' &&
      saleChannels.forEach(c => {
        if (c.name === newValue) {
          id = c.id;
        }
      });
    name === 'default_point_of_sale' &&
      this.props.supplierInfo.pointOfSales.forEach(c => {
        if (c.name === newValue) {
          id = c.id;
        }
      });

    this.setState({
      [name]: newValue,
      [`${name}_id`]: id,
      api_errors: null
    });
  };
  _handleOpenModal = () => this.setState({ isOpen: true });
  _handleCloseModal = () => this.setState({ isOpen: false });

  _handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  _validations = () => {
    const {
      default_sale_channel,
      default_sale_channel_id,
      can_select_sale_channel,
      default_point_of_sale,
      default_point_of_sale_id,
      can_select_point_of_sale,
      doc_revision_percentage
    } = this.state;
    const { api_errors } = this.props;
    const posVal = pointOfSaleValidation(
      default_point_of_sale,
      default_point_of_sale_id,
      can_select_point_of_sale,
      api_errors,
      true
    );
    const scVal = saleChannelValidation(
      default_sale_channel,
      default_sale_channel_id,
      can_select_sale_channel,
      api_errors,
      true
    );
    if (posVal || scVal) {
      return true;
    }

    if (doc_revision_percentage < 0 || doc_revision_percentage > 100) {
      return true;
    }

    return false;
  };
  render() {
    const { supplierInfo, api_errors, loading, classes, onClose } = this.props;

    const docRevisionPercentageError = this.state.doc_revision_percentage < 0 || this.state.doc_revision_percentage > 100;

    return (
      <Modal
        open={true}
        onClose={onClose}
        classes={{
          footer: classes.modalFooter,
          root: classes.modalRoot
        }}
        body={
          <Fragment>
            <Typography className={classes.cardTitle}>
              CONFIGURACIÓN REGISTRO DE VENTA
            </Typography>
            <Grid container spacing={24}>
              <Grid container spacing={24}>
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    id="allowed_promissory_type"
                    name="allowed_promissory_type"
                    required
                    label="Tipo de pagaré permitido"
                    disabled={loading}
                    value={this.state.allowed_promissory_type}
                    onChange={this._handleChangeSelect}
                    menuItems={promissoryTypes}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    id="order_of_processes"
                    name="order_of_processes"
                    required
                    label="Orden de los procesos"
                    disabled={loading}
                    value={this.state.order_of_processes}
                    onChange={this._handleChangeSelect}
                    menuItems={processesOrder}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AutoSuggestInput
                    id="default_point_of_sale"
                    name="default_point_of_sale"
                    label="Punto de venta por defecto"
                    disabled={loading}
                    value={this.state.default_point_of_sale}
                    onChange={this._handleAutoSuggest('default_point_of_sale')}
                    attr="name"
                    suggestions={
                      supplierInfo.pointOfSales.length > 0
                        ? supplierInfo.pointOfSales
                        : []
                    }
                    error={pointOfSaleValidation(
                      this.state.default_point_of_sale,
                      this.state.default_point_of_sale_id,
                      this.state.can_select_point_of_sale,
                      api_errors,
                      true
                    )}
                    helperText={pointOfSaleValidation(
                      this.state.default_point_of_sale,
                      this.state.default_point_of_sale_id,
                      this.state.can_select_point_of_sale,
                      api_errors
                    )}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AutoSuggestInput
                    id="default_sale_channel"
                    name="default_sale_channel"
                    label="Canal de venta por defecto"
                    disabled={loading}
                    value={this.state.default_sale_channel}
                    onChange={this._handleAutoSuggest('default_sale_channel')}
                    attr="name"
                    suggestions={this.props.saleChannels}
                    margin="normal"
                    variant="outlined"
                    error={saleChannelValidation(
                      this.state.default_sale_channel,
                      this.state.default_sale_channel_id,
                      this.state.can_select_sale_channel,
                      api_errors,
                      true
                    )}
                    helperText={saleChannelValidation(
                      this.state.default_sale_channel,
                      this.state.default_sale_channel_id,
                      this.state.can_select_sale_channel,
                      api_errors
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="doc_revision_percentage"
                    name="doc_revision_percentage"
                    value={this.state.doc_revision_percentage}
                    onChange={this._handleChange}
                    label="% de ventas con rev. documental"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={docRevisionPercentageError}
                    helperText={docRevisionPercentageError ? 'El porcentaje debe estar entre 0 y 100' : ''}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="is_big_box"
                      checked={this.state.is_big_box}
                      onChange={this.handleCheckBox('is_big_box')}
                    />
                  }
                  label="Grande superficie"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="can_reprint_sale_documents"
                      checked={this.state.can_reprint_sale_documents}
                      onChange={this.handleCheckBox(
                        'can_reprint_sale_documents'
                      )}
                    />
                  }
                  label="Puede re-imprimir documentos de venta"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="requires_bin"
                      checked={this.state.requires_bin}
                      onChange={this.handleCheckBox('requires_bin')}
                    />
                  }
                  label="Generación de BIN"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="can_select_point_of_sale"
                      checked={this.state.can_select_point_of_sale}
                      onChange={this.handleCheckBox('can_select_point_of_sale')}
                    />
                  }
                  label="Puede seleccionar punto de venta"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="site_delivery"
                      checked={this.state.site_delivery}
                      onChange={this.handleCheckBox('site_delivery')}
                    />
                  }
                  label="Entrega en punto"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="can_change_site_delivery"
                      checked={this.state.can_change_site_delivery}
                      onChange={this.handleCheckBox('can_change_site_delivery')}
                    />
                  }
                  label="Puede cambiar entrega en punto"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="requires_approval_before_sale_cancel"
                      checked={this.state.requires_approval_before_sale_cancel}
                      onChange={this.handleCheckBox(
                        'requires_approval_before_sale_cancel'
                      )}
                    />
                  }
                  label="Requiere aprobación antes de cancelar ventas"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="can_select_sale_channel"
                      checked={this.state.can_select_sale_channel}
                      onChange={this.handleCheckBox('can_select_sale_channel')}
                    />
                  }
                  label="Puede seleccionar canales de venta"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="requires_approval_before_sale_return"
                      checked={this.state.requires_approval_before_sale_return}
                      onChange={this.handleCheckBox(
                        'requires_approval_before_sale_return'
                      )}
                    />
                  }
                  label="Requiere aprobación antes de devolver ventas"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="reports_show_voluntary_insurance"
                      checked={this.state.reports_show_voluntary_insurance}
                      onChange={this.handleCheckBox(
                        'reports_show_voluntary_insurance'
                      )}
                    />
                  }
                  label="Seguro voluntario se muestra en los reportes"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="can_sell_with_secure_sale"
                      checked={this.state.can_sell_with_secure_sale}
                      onChange={this.handleCheckBox(
                        'can_sell_with_secure_sale'
                      )}
                    />
                  }
                  label="Puede vender con fianza"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="can_skip_invoice_validation"
                      checked={this.state.can_skip_invoice_validation}
                      onChange={this.handleCheckBox(
                        'can_skip_invoice_validation'
                      )}
                    />
                  }
                  label="Puede omitir paso de validación de factura"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="can_validate_mobile_on_sale"
                      checked={this.state.can_validate_mobile_on_sale}
                      onChange={this.handleCheckBox(
                        'can_validate_mobile_on_sale'
                      )}
                    />
                  }
                  label="Puede validar celular en registro de venta"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="can_sell_voluntary_insurance_layer_two"
                      checked={this.state.can_sell_voluntary_insurance_layer_two}
                      onChange={this.handleCheckBox(
                        'can_sell_voluntary_insurance_layer_two'
                      )}
                    />
                  }
                  label="Puede vender seguro voluntario complementario"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="can_sell_with_megaquota"
                      checked={this.state.can_sell_with_megaquota}
                      onChange={this.handleCheckBox(
                        'can_sell_with_megaquota'
                      )}
                    />
                  }
                  label="Puede vender con Megacupo"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="can_validate_pet_sale"
                      checked={this.state.can_validate_pet_sale}
                      onChange={this.handleCheckBox(
                        'can_validate_pet_sale'
                      )}
                    />
                  }
                  label="Puede validar mascotas en registro de venta"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="can_sell_to_commercial"
                      checked={this.state.can_sell_to_commercial}
                      onChange={this.handleCheckBox(
                        'can_sell_to_commercial'
                      )}
                    />
                  }
                  label="Puede vender a comercial"
                />
              </Grid>
            </Grid>
            <ConfirmDialogModal
              open={this.state.isOpen}
              title={'Advertencia:'}
              text={`Al activar la generación del BIN para este proveedor estás solicitando implicitamente una configuración general para este, si deseas que este trámite se realize satisfactoriamente, debes contactar a tu administrador.`}
              buttonTextConfirm={'OK'}
              onClose={this._handleCloseModal}
              onConfirm={this._handleCloseModal}
              customBase={classes.customBase}
            />
          </Fragment>
        }
        footer={
          <Fragment>
            <div className={classes.spacer} />
            <LoadingButton
              size="large"
              color="secondary"
              loading={loading}
              disabled={this._validations()}
              onClick={() => this.props.updateSupplier(this.state)}
            >
              GUARDAR
            </LoadingButton>
          </Fragment>
        }
      />
    );
  }
}
const styles = theme => ({
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: '#CACACA',
    paddingBottom: theme.spacing.unit * 3
  },
  card: {
    marginBottom: theme.spacing.unit * 3
  },
  customBase: {
    width: 500
  }
});

SupplierEditConfig.propTypes = {
  supplierInfo: PropTypes.object,
  updateSupplierConfig: PropTypes.func,
  pointOfSales: PropTypes.array,
  api_errors: PropTypes.object
};

export default withStyles(styles)(SupplierEditConfig);
