/* eslint-disable*/
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  withStyles
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloudDownload from '@material-ui/icons/CloudDownload';

import classnames from 'classnames';
import _capitalize from 'lodash/capitalize';
import _debounce from 'lodash/debounce';
import _every from 'lodash/every';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import _join from 'lodash/join';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import combineStyles from '../../../helpers/combineStyles';
import { _moneyFormat } from '../../../helpers/utilityFunctions';

import TypographyStyles from '../../../styles/typography';
import ButtonStyles from '../../../styles/button';

import ActivityIndicator from '../../shared/ActivityIndicator';
import ToggleButton from '../../shared/Buttons/Toggle';
import Masks, { formatBack } from '../../shared/Inputs/Masks';
import CustomDialog from '../../shared/Modals/CustomDialog';
import SimpleTable from '../../shared/Tables/SimpleTable';

import { contractValidation, valueValidation } from './ManualQuotaValidations';
import InputFile from '../../shared/Inputs/InputFile';
import { readXLSFile } from '../../../helpers/fileFunctions';
import BulkLoad from '../../shared/BulkLoad/BulkLoad';
// import FlashMessage from '../../shared/Alerts/Flash/FlashMessage';

class ManualQuotaCreateEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract_id: '',
      value: '',
      observations: '',
      print_in_bill: false,
      api_errors: null,
      massiveSelected: false,
      massivePrintInBill: false,
      file: ''
    };
  }

  _fetchContractInfo = () => {
    const { contract_id } = this.state;
    contract_id &&
      this.props.fetchContract(contract_id).then(r => {
        if (!r.success) {
          this.setState({ api_errors: r.errors });
        }
      });
  };
  _handleContract = _debounce(
    () => {
      this._fetchContractInfo();
    },
    1000,
    {
      leading: false,
      trailing: true
    }
  );
  _handleChangeContract = ({ target: { name, value } }) => {
    this._handleContract(name, value);
  };
  _setItem = manualQuotaDetail => {
    const { contract_id, value, print_in_bill } = manualQuotaDetail;
    this.setState({
      contract_id: contract_id || '',
      value: value || '',
      observations: '',
      print_in_bill: print_in_bill || false,
      api_errors: null
    });
  };
  _handleSave = () => {
    if (this.state.massiveSelected) {
      const { file, massivePrintInBill } = this.state;
      this.props
        .createMassiveManualQuota({ file, massivePrintInBill })
        .then(r => {
          this.file = '';

          if (r.success) {
            this._handleClose();
          }
        });
    } else {
      const {
        api_errors, 
        contract_id,
        file,
        massiveSelected,
        massivePrintInBill,
        observations,
        print_in_bill,
        value
      } = this.state;
      const testBody = {
        contractId: Number(contract_id),
        observations,
        printInBill: print_in_bill,
        apiErrors: api_errors,
        file,
        massiveSelected,
        massivePrintInBill,
        value: formatBack(value, 'currency')
      };
      this.props.manualQuotaCreate(testBody).then(r => {
        if (r.success) {
          if (this.props.reFetch) {
            this.props.reFetch();
          }
          this.setState({ success: true });
          this._handleClose();
        } else {
          this.setState({ api_errors: r.errors });
        }
      });
    }
  };

  _handleClose = () => {
    const { requestSend } = this.props;
    if (!requestSend) {
      this.props.clearContract();
      this.props.onClose();
    }
  };

  _handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value, api_errors: null });
    if (name == 'contract_id') {
      this._handleContract(value);
    }
  };

  handleChangeFile = file => {
    this.setState({ file: file });
  };

  handleCheckBox = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  _disableSubmit = () => {
    // const { rangeDetail, isEditing } = this.props;
    if (this.state.massiveSelected) {
      return !this.state.file;
    } else {
      const { api_errors } = this.state;
      const cantBeEmpty = {
        ...this.state,
        api_errors: 'no-check',
        print_in_bill: 'no-check',
        file: 'no-check',
        massiveSelected: 'no-check',
        massivePrintInBill: 'no-check'
      };
      if (!_every(cantBeEmpty, e => !!e)) return true;
      if (contractValidation(api_errors, true)) return true;
      if (valueValidation(api_errors, true)) return true;
      return false;
    }
  };

  _getManualQuotas = contractInfo => {
    if (!contractInfo) return '';
    const requests = contractInfo.manual_quota;
    if (requests.length == 0) return '';
    const grouped = _groupBy(requests, 'status');
    if (grouped.registered || grouped.approved) {
      const sum =
        _get(grouped, 'registered.length', 0) +
        _get(grouped, 'approved.length', 0);

      return `El contrato tiene ${sum} ${sum == 1 ? 'solicitud pendiente' : 'solicitudes pendientes'
        }...`;
    }
    return '';
  };

  _getContract = contractInfo => {
    if (!contractInfo) return [];
    const contract = {
      key: contractInfo.id,
      name: contractInfo.name + ' ' + contractInfo.lastName,
      identification: contractInfo.identification || '--',
      locality: contractInfo.locality || '--',
      address: contractInfo.address || '--'
    };
    return [contract];
  };

  _getPolicies = contractInfo => {
    if (!contractInfo) return '';
    const breachedPolicies = contractInfo.breachedPolicies;
    if (breachedPolicies.data.length === 0) return 'Este contrato cumple con las políticas de Brilla.';

    const breaches = breachedPolicies.data.map(policy => _capitalize(policy.name || ''));
    if (breachedPolicies.networkBalance) breaches.push('SALDO DE RED');

    if (breaches.length === 0) return '';

    return `Este contrato incumple ciertas políticas: ${_join(breaches, ', ')}.`;
  };

  render() {
    const {
      classes,
      isEditing,
      requestSend,
      requestSendContract,
      requestSendMassive,
      contractDetail,
      massiveManualQuotaActive,
      canCreateMassiveManualQuota
    } = this.props;
    const { api_errors, observations, massiveSelected, massivePrintInBill } = this.state;

    return (
      <div>
        <CustomDialog
          open={true}
          loading={requestSend || requestSendMassive}
          disabled={
            this._disableSubmit() ||
            requestSend ||
            requestSendContract ||
            requestSendMassive
          }
          disableBackdropClick={true}
          headerSecondary={<div />}
          onClose={this._handleClose}
          onConfirm={() => this._handleSave(false)}
          title={
            <div className={classes.titleContainer}>
              <p className={classnames(classes.headline6, classes.title)}>
                {`${isEditing ? 'Editar' : 'Crear'
                  } Solicitud de Cupo Manual`}
              </p>
              {massiveManualQuotaActive && canCreateMassiveManualQuota && (
                <ToggleButton
                  value={massiveSelected}
                  onChange={() => {
                    this.setState({ massiveSelected: !massiveSelected });
                  }}
                  inactiveLabel={'Único'}
                  activeLabel={'Masivo'}
                />
              )}
            </div>
          }
          buttonText={'Guardar'}
          classes={{
            root: classes.dialogRoot
          }}
        >
          {!massiveSelected && (
            <Grid container spacing={16}>
              <Grid item xs={6}>
                <TextField
                  name={'contract_id'}
                  value={this.state.contract_id}
                  label={'# Contrato*'}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  onChange={this._handleChange}
                  disabled={requestSend}
                  InputProps={{
                    endAdornment: requestSendContract ? (
                      <ActivityIndicator size={24} />
                    ) : null
                  }}
                  error={contractValidation(api_errors, true)}
                  helperText={contractValidation(api_errors)}
                />
              </Grid>
              <Grid item xs={6} className={classes.messageContainer}>
                {/* this._getManualQuotas(contractDetail) &&
                !requestSendContract && (
                  <FlashMessage
                    variant="info"
                    message={this._getManualQuotas(contractDetail)}
                    actionClick={() => null}
                  />
                ) */}
              </Grid>
              {!requestSendContract && contractDetail && (
                <Grid item xs={12} className={classes.table}>
                  <SimpleTable
                    headers={[
                      {
                        name: 'name',
                        title: 'Titular '
                      },
                      {
                        name: 'identification',
                        title: 'Cédula'
                      },
                      {
                        name: 'locality',
                        title: 'Ciudad'
                      },
                      {
                        name: 'address',
                        title: 'Dirección'
                      }
                    ]}
                    rows={this._getContract(contractDetail)}
                    subText={this._getPolicies(contractDetail)}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <TextField
                  name={'value'}
                  value={this.state.value}
                  onChange={this._handleChange}
                  disabled={requestSend}
                  label={'Valor*'}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    inputComponent: Masks.currency,
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    )
                  }}
                  error={valueValidation(api_errors, true)}
                  helperText={valueValidation(api_errors)}
                />
              </Grid>
              <Grid item xs={6} className={classes.checkContainer}>
                <FormControlLabel
                  className={classes.check}
                  control={
                    <Checkbox
                      disabled={requestSend}
                      name="print_in_bill"
                      checked={this.state.print_in_bill}
                      onChange={this.handleCheckBox('print_in_bill')}
                    />
                  }
                  label="Imprime factura"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="observations"
                  value={observations}
                  onChange={this._handleChange}
                  label="Motivo*"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  disabled={requestSend}
                  multiline={true}
                  rows={2}
                  inputProps={{ maxLength: 199 }}
                />
              </Grid>
            </Grid>
          )}
          {massiveSelected &&
            massiveManualQuotaActive &&
            canCreateMassiveManualQuota && (
              <BulkLoad
                templatePath={'/docs/plantilla-cupo-manual-masivo.xlsx'}
                handleChangeFile={this.handleChangeFile}
                massivePrintInBill={massivePrintInBill}
                onChangeCheckBox={this.handleCheckBox('massivePrintInBill')}
                isManualQuota
              />
            )}
        </CustomDialog>
      </div>
    );
  }
}

const styles = theme => ({
  check: {
    marginLeft: 0,
    marginTop: theme.spacing.unit * 2.5
  },
  checkContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  messageContainer: {
    marginTop: theme.spacing.unit * 2.5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  table: {
    marginBottom: theme.spacing.unit
  },
  dialogRoot: {
    width: 800
  },
  titleContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  noDecorationAnchor: {
    textDecoration: 'none'
  }
});

ManualQuotaCreateEdit.propTypes = {
  open: PropTypes.bool
};

export default withStyles(
  combineStyles(styles, TypographyStyles, ButtonStyles),
  {
    withTheme: true
  }
)(ManualQuotaCreateEdit);
